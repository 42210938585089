import { createSignal } from "solid-js"

export const AccountIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    class="dark:fill-gray-900 fill-gray-200"
  >
    <g>
      <path d="M3.66 17.52a10 10 0 1 1 16.68 0C19.48 16.02 17.86 16 16 16H8c-1.86 0-3.48.01-4.34 1.52z" />
      <path
        class="dark:fill-gray-600 fill-gray-400"
        d="M3.66 17.52A5 5 0 0 1 8 15h8a5 5 0 0 1 4.34 2.52 10 10 0 0 1-16.68 0zM12 13a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
      />
    </g>
  </svg>
)

const AccountMenu = ({ signedIn = false }: { signedIn?: boolean }) => {
  // open and close menu
  const [open, setOpen] = createSignal(false)
  // show menu
  return (
    <div class="relative">
      <button
        onClick={() => setOpen(!open())}
        class="border border-gray-600 h-9 w-9 rounded-full"
      >
        <AccountIcon />
      </button>
      {open() && (
        <div class="absolute right-0 top-0 mt-12 w-48 bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden">
          {signedIn ? (
            <>
              <a
                href="/settings"
                class="block px-4 py-2 text-gray-800 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700"
              >
                Settings
              </a>
              <button
                onClick={async () => {
                  await fetch("/api/auth/signout", {
                    method: "DELETE",
                  })
                  setOpen(false)
                  window.location.href = "/signedout"
                }}
                class="block px-4 py-2 text-gray-800 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700 w-full text-left"
              >
                Sign Out
              </button>
            </>
          ) : (
            <a
              href="/signin"
              class="block px-4 py-2 text-gray-800 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700"
            >
              Sign In
            </a>
          )}
        </div>
      )}
    </div>
  )
}
export default AccountMenu
